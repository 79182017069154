<template>
  <div class="boat-rental">
    <div class="boat-rental-landing" v-for="rental in boatRentals" :key="rental.id">
      <div class="boat-rental-landing-inner" v-if="proId === rental.id">
        <video
            :src="coverImage(rental.video)"
            type="video/mp4" autoplay loop :poster="coverImage(rental.image)"></video>
        <div class="boat-rental-overlay">
          <div class="boat-rental-inner">
            <h1>{{ rental.title }}</h1>
            <p>{{ rental.subtitle }}</p>
          </div>
        </div>
        <div class="boat-rental-details">
          <div class="boat-rental-left-details">
            <ul>
              <li class="boat-rental-title-no-underline"><h4>Details</h4></li>
              <li v-for="item in rental.tourSummary" :key="item.id">
                <p v-html="item.tourSummaryItem"></p>
              </li>

            </ul>


          </div>
          <div class="boat-rental-right-details">

            <ul>
              <li class="boat-rental-title-no-underline"><h4>Specifications</h4></li>
              <li v-for="addItem in rental.additionalInformation" :key="addItem.id">
                <p v-html="addItem.addInfItem"></p>
              </li>

            </ul>


          </div>
        </div>
        <div class="boat-rental-description">
          <div class="boat-rental-desc-right">
            <div class="descImage mobile-image" v-bind:style="{'background': 'url('+ coverImage(rental.descImageOne) + ') no-repeat center center', 'background-size': 'cover'}"><span role="img" aria-label="[boat rent hvar]"></span></div>
            <div class="descImage" v-bind:style="{'background': 'url('+ coverImage(rental.descImageTwo) + ') no-repeat center center', 'background-size': 'cover'}"><span role="img" aria-label="[hvar boat rental]"></span></div>


          </div>
          <div class="boat-rental-desc-left">
            <h4>Hvar Island – Boat rental Hvar Details</h4>
            <div class="boat-rental-underline"></div>
            <p v-html="rental.description"></p>
          </div>

        </div>
        <div class="boat-rental-gallery">
          <agile :initial-slide="1" :options="myOptions">
            <img v-for="photo in rental.gallery" :key="photo.id" class="slide" :src="coverImage(photo.img)" alt="hvar boat rental">
            <template slot="prevButton"><i class="fas fa-chevron-left"></i></template>
            <template slot="nextButton"><i class="fas fa-chevron-right"></i></template>
          </agile>
        </div>
        <div class="landing-contact">
          <div class="contact-inner">
            <h3>Enquire</h3>
            <div v-if="errors.length">
              <b>Please correct the following error(s):</b>
              <ul>
                <li v-for="error in errors" :key="error.id">{{ error }}</li>
              </ul>
            </div>
            <div class="contact-line"></div>
            <form @submit="checkForm">
              <input type="text" v-model="name" name="name" class="name" placeholder="NAME">
              <input type="text" v-model="email" name="email" class="email" placeholder="E-MAIL">
              <input type="text" v-model="title" name="title" class="title" placeholder="TITLE">
              <textarea v-model="message" name="message" class="content" id="" cols="30" rows="10"
                        placeholder="ENQUIRY "></textarea>
              <button type="submit">Send</button>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import boatRentalsData from '@/data/boatRentalsData.json'
import {VueAgile} from 'vue-agile'
import emailjs from 'emailjs-com'
import Modal from "@/views/Modal";
import store from "@/store";

export default {
  metaInfo() {
    return {
      title: `${this.seoTitle}`,
      meta: [
        {name: "robots", content: "index,follow"},
        {
          name: 'description',
          content: `${this.seoContent}`

        }

      ],
      link: [
        {rel: 'favicon', href: 'logo.ico'}
      ],
      script: [{
        type: 'application/ld+json',
        json: this.markups,
      }]
    }
  },
  data() {
    const SEOdata = store.getters.getBoatRental(this.$route.params.id);
    return {
      boatRentals: boatRentalsData,
      proId: this.$route.params.id,
      seoTitle: SEOdata.seoTitle,
      seoContent: SEOdata.seoContent,
      markups: SEOdata.markup,
      name: '',
      email: '',
      title: '',
      message: '',
      errors: [],

      myOptions: {
        navButtons: false,

        responsive: [
          {
            breakpoint: 350,
            settings: {
              dots: false,
              navButtons: true
            }
          },
          {
            breakpoint: 480,
            settings: {
              dots: false,
              navButtons: true
            }
          },

          {
            breakpoint: 900,
            settings: {
              navButtons: true,
              dots: true,
              infinite: false

            }
          }
        ]
      }
    }
  },
  components: {
    agile: VueAgile
  },
  methods: {
    checkForm(e) {
      this.errors = [];

      if (!this.name) {
        this.errors.push("Name required.");
      }
      if (!this.email) {
        this.errors.push('Email required.');
      } else if (!this.validEmail(this.email)) {
        this.errors.push('Valid email required.');
      }
      if (!this.title) {
        this.errors.push("Title required.");
      }
      if (!this.message) {
        this.errors.push("Message required.");
      }

      if (!this.errors.length) {
        try {
          emailjs.sendForm('service_8xsw9aa', 'template_19ompn2', e.target,
              'user_OReXS1Etru6y4xeTBAltW', {
                name: this.name,
                email: this.email,
                title: this.title,
                message: this.message
              })

        } catch (error) {
          console.log({error})
        }
        this.$modal.show(Modal)
        setTimeout(()=>{
          this.$modal.hideAll()
        },4000);
        this.name = ''
        this.email = ''
        this.title = ''
        this.message = ''

      }

      e.preventDefault();
    },
    validEmail: function (email) {
      const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      return re.test(email);
    },
    sendEmail(e) {
      try {
        emailjs.sendForm('service_8xsw9aa', 'template_19ompn2', e.target,
            'user_OReXS1Etru6y4xeTBAltW', {
              name: this.name,
              email: this.email,
              title: this.title,
              message: this.message
            })

      } catch (error) {
        console.log({error})
      }
      // Reset form field
      this.name = ''
      this.email = ''
      this.title = ''
      this.message = ''
    },
    coverImage: function (path){
      return require("@/" + path);
    }
  }


}
</script>