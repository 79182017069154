<template>
  <div class="boat-rental">
    <div class="boat-rental-landing">
      <video
          src=""
          type="video/mp4" autoplay poster="../assets/rentCover.jpg"></video>
      <div class="boat-rental-overlay">
        <div class="boat-rental-inner">
          <h1>Hvar Boat Hire: Your Gateway to the Adriatic's Hidden Gems</h1>
          <p>rent a boat with the crew</p>
        </div>
      </div>
      <div class="boat-rental-small-bar">
        <h2>Tailoring Your Journey: Customizable Boat Hire Services</h2>
        <p>Embarking on a sea adventure in Hvar is not just about renting a boat; it's about creating an experience
          that's uniquely yours. With a wide range of customizable boat hire services, you have the freedom to design
          your journey down to the last detail. </p>
      </div>
      <div class="boat-rental-landing-second">
        <div class="landing-group-tours box" v-for="rent in boatRent" :key="rent.id">
          <router-link
              :to="{name: 'rentals', params:{id: rent.id, title: rent.seoTitle, content: rent.seoContent, markup:rent.markup}}">
            <div class="image"
                 v-bind:style="{'background': 'url('+ coverImage(rent.image) + ') no-repeat center center', 'background-size': 'cover'}">
              <span role="img" aria-label="[hvar boat rental]"></span>
              <div class="overlay-title">
                <h4 class="hover-title">{{ rent.title }}</h4>
                <div class="hover-title-line"></div>
                <p>{{ rent.shortDescription }} </p>
              </div>
              <h4 class="title">{{ rent.titleTwo }}</h4>
            </div>
          </router-link>
        </div>
      </div>
      <div class="landing-contact">
        <div class="contact-inner">
          <h3>Enquire</h3>
          <div v-if="errors.length">
            <b>Please correct the following error(s):</b>
            <ul>
              <li v-for="error in errors" :key="error.id">{{ error }}</li>
            </ul>
          </div>
          <div class="contact-line"></div>
          <form @submit="checkForm">
            <input type="text" v-model="name" name="name" class="name" placeholder="NAME">
            <input type="text" v-model="email" name="email" class="email" placeholder="E-MAIL">
            <input type="text" v-model="title" name="title" class="title" placeholder="TITLE">
            <textarea v-model="message" name="message" class="content" id="" cols="30" rows="10"
                      placeholder="ENQUIRY "></textarea>
            <button type="submit">Send</button>
          </form>
        </div>
      </div>
    </div>

  </div>
</template>

<script>
import boatRentalsData from '@/data/boatRentalsData.json'
import emailjs from 'emailjs-com'
import Modal from "@/views/Modal";

export default {
  metaInfo() {
    return {
      title: ' Hvar Boat Hire: Book Your Perfect Sea Adventure Today ',
      meta: [
        {name: "robots", content: "index,follow"},
        {
          name: 'description',
          content: 'Dive into the crystal-clear Adriatic with our top-rated Hvar Boat Hire. Discover hidden coves, majestic cliffs, and personalized services. Book your voyage now!'
        }

      ],
      link: [
        {rel: 'favicon', href: 'logo.ico'}
      ]
    }
  },
  data() {
    return {
      boatRent: boatRentalsData,
      name: '',
      email: '',
      title: '',
      message: '',
      errors: [],
    }
  },
  methods: {
    checkForm(e) {
      this.errors = [];

      if (!this.name) {
        this.errors.push("Name required.");
      }
      if (!this.email) {
        this.errors.push('Email required.');
      } else if (!this.validEmail(this.email)) {
        this.errors.push('Valid email required.');
      }
      if (!this.title) {
        this.errors.push("Title required.");
      }
      if (!this.message) {
        this.errors.push("Message required.");
      }

      if (!this.errors.length) {
        try {
          emailjs.sendForm('service_8xsw9aa', 'template_19ompn2', e.target,
              'user_OReXS1Etru6y4xeTBAltW', {
                name: this.name,
                email: this.email,
                title: this.title,
                message: this.message
              })

        } catch (error) {
          console.log({error})
        }
        this.$modal.show(Modal)
        setTimeout(() => {
          this.$modal.hideAll()
        }, 4000);
        this.name = ''
        this.email = ''
        this.title = ''
        this.message = ''

      }

      e.preventDefault();
    },
    validEmail: function (email) {
      const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      return re.test(email);
    },
    sendEmail(e) {
      try {
        emailjs.sendForm('service_8xsw9aa', 'template_19ompn2', e.target,
            'user_OReXS1Etru6y4xeTBAltW', {
              name: this.name,
              email: this.email,
              title: this.title,
              message: this.message
            })

      } catch (error) {
        console.log({error})
      }

      // Reset form field
      this.name = ''
      this.email = ''
      this.title = ''
      this.message = ''
    },
    coverImage: function (path) {
      return require("@/" + path);
    }
  }
}
</script>