<template>
  <div class="group-tour">
    <div class="group-tour-landing" v-for="tour in groupTour" :key="tour.id">
      <video
          src="../assets/cover.mp4"
          type="video/mp4" poster="../assets/bluecave2.jpg" autoplay></video>
      <div class="group-tour-overlay">
        <div class="group-tour-inner">
          <h1>{{ tour.title }}</h1>
          <p>{{ tour.subtitle }}</p>
          <button
                  @click="addParamsToUrl(448336)" class="book-button">Book now!
          </button>
        </div>
      </div>
      <div class="group-tour-details">
        <div class="group-tour-left-details">
          <ul>
            <li class="group-tour-title-no-underline"><h4>Tour Summary</h4></li>
            <li v-for="item in tour.tourSummary" :key="item.id">
              <p v-html="item.tourSummaryItem"></p>
            </li>

          </ul>


        </div>
        <div class="group-tour-right-details">

          <ul>
            <li class="group-tour-title-no-underline"><h4>Aditional information</h4></li>
            <li v-for="addItem in tour.additionalInformation" :key="addItem.key">
              <p v-html="addItem.addInfItem"></p>
            </li>

          </ul>

          <ul class="group-tour-highlight">
            <li class="group-tour-title-no-underline"><h4>TOUR HIGHLIGHTS </h4></li>
            <li><p v-html="tour.tourHighlights"></p></li>
          </ul>
        </div>
      </div>
      <div class="group-tour-description">
        <div class="group-tour-desc-right">
          <div class="descImage mobile-image" v-bind:style="{'background': 'url('+ coverImage(tour.descImageOne) + ') no-repeat center center', 'background-size': 'cover'}"></div>
          <div class="descImage" v-bind:style="{'background': 'url('+ coverImage(tour.descImageTwo) + ') no-repeat center center', 'background-size': 'cover'}"></div>

        </div>
        <div class="group-tour-desc-left">
          <h4>Blue cave boat Tour Details</h4>
          <div class="group-tour-underline"></div>
          <p v-html="tour.description"></p>
        </div>
      </div>
      <div class="group-tour-gallery">
        <agile :initial-slide="1" :options="myOptions">
          <img v-for="photo in tour.gallery" :key="photo.id" class="slide" :src="coverImage(photo.img)" alt="blue cave tour from hvar">
          <template slot="prevButton"><i class="fas fa-chevron-left"></i></template>
          <template slot="nextButton"><i class="fas fa-chevron-right"></i></template>
        </agile>
      </div>
      <div class="backlink-title">
      <h4>group tours are not your cup of tea?</h4>
      </div>
      <div class="backlink">
        <div class="backlink-inner box">
          <router-link to="/hvar-boat-tours-top-rated-adventures">
            <div class="privateImage image">
              <div class="overlay-title">
                <h4 class="hover-title">Private boat tours</h4>
                <div class="hover-title-line"></div>
                <p>Enjoy unique tailored speed boat tours and discover Blue cave Croatia, the best hvar beaches, zlatni rat
                  beach
                  and amazing Pakleni islands</p>
              </div>
              <h4 class="title">Private boat tours</h4>
            </div>
          </router-link>
        </div>
        <div class="backlink-inner box">
          <router-link to="/hvar-boat-hire-adriatics-hidden-gems">
            <div class="rentalImage image">
              <div class="overlay-title">
                <h4 class="hover-title">hvar boat rental</h4>
                <div class="hover-title-line"></div>
                <p>Rent a boat with crew in Hvar and explore maze of Pakleni islands, unique blue cave Croatia, or one of the
                  best
                  beaches in Europe Stiniva</p>
              </div>
              <h4 class="title">hvar boat rental</h4>
            </div>
          </router-link>
        </div>
      </div>
      <div class="landing-contact">
        <div class="contact-inner">
          <h3>Enquire</h3>
          <div v-if="errors.length">
            <b>Please correct the following error(s):</b>
            <ul>
              <li v-for="error in errors" :key="error.id">{{ error }}</li>
            </ul>
          </div>
          <div class="contact-line"></div>
          <form @submit="checkForm">
            <input type="text" v-model="name" name="name" class="name" placeholder="NAME">
            <input type="text" v-model="email" name="email" class="email" placeholder="E-MAIL">
            <input type="text" v-model="title" name="title" class="title" placeholder="TITLE">
            <textarea v-model="message" name="message" class="content" id="" cols="30" rows="10"
                      placeholder="ENQUIRY "></textarea>
            <button type="submit">Send</button>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import groupToursData from '@/data/groupToursData.json'
import {VueAgile} from 'vue-agile'
import emailjs from 'emailjs-com'
import Modal from "@/views/Modal";

export default {
  metaInfo() {
    return {
      title: 'Blue Cave Croatia Boat Tour: Discover the Magical Blue Cave and Nearby Attractions',
      meta: [
        {name: "robots", content: "index,follow"},
        {
          name: 'description',
          content: 'Embark on an unforgettable adventure exploring the mesmerizing Blue Cave Croatia and its stunning surroundings. Visit the Green Cave, relax at Stiniva Beach, and unwind at Palmižana Beach on the Pakleni Islands. Book your boat tour today!'
        }

      ],
      link: [
        {rel: 'favicon', href: 'logo.ico'}
      ],
      script: [{
        type: 'application/ld+json',
        json: {
          "@context": "https://schema.org/",
          "@type": "Product",
          "name": "Blue cave boat tour ",
          "image": [
            "../static/images/bluecave.jpg"
          ],
          "description": "Witness the magnificence of the Blue cave on the Biševo island. Take a dip in the emerald waters of the Green cave and float in Stiniva cove. Strap on your diving mask and explore thriving underwater world of the Vis island and swim inside amazing Budikovac Blue lagoon.",
          "brand": {
            "@type": "Brand",
            "name": "Hvar Boating"
          },
          "review": {
            "@type": "Review",
            "reviewRating": {
              "@type": "Rating",
              "ratingValue": "5",
              "bestRating": "5"
            },
            "author": {
              "@type": "Person",
              "name": "Aleksandar Markovski "
            }
          },
          "aggregateRating": {
            "@type": "AggregateRating",
            "ratingValue": "5",
            "reviewCount": "7"
          },
          "offers": {
            "@type": "Offer",
            "url": "/blue-caves-croatia/",
            "priceCurrency": "USD",
            "price": "70",
            "priceValidUntil": "2025-11-1",
            "availability": "https://schema.org/LimitedAvailability"
          },
        }
      }]
    }
  },
  data() {
    return {
      groupTour: groupToursData,
      name: '',
      email: '',
      title: '',
      message: '',
      errors: [],

      myOptions: {
        navButtons: false,

        responsive: [
          {
            breakpoint: 350,
            settings: {
              dots: false,
              navButtons: true
            }
          },
          {
            breakpoint: 480,
            settings: {
              dots: false,
              navButtons: true
            }
          },

          {
            breakpoint: 900,
            settings: {
              navButtons: true,
              dots: true,
              infinite: false

            }
          }
        ]
      }
    }
  },
  components: {
    agile: VueAgile
  },

  mounted() {
    //const plugin = document.createElement("script");
    //plugin.setAttribute(
      //  "src",
       // "https://cdn.getyourguide.com/partner-ticketing/latest/ticketing.umd.min.js"
   // );
  //  plugin.async = true;
    //document.head.appendChild(plugin);

  },
  methods: {
      addParamsToUrl(activityId) {
          const params = new URLSearchParams({
              partner_id: '1001SHJ',
              partner_ticketing_activity_ids: activityId,
          });

          window.location.href = `${window.location.origin}${window.location.pathname}?${params.toString()}`;
      },
    checkForm(e) {
      this.errors = [];

      if (!this.name) {
        this.errors.push("Name required.");
      }
      if (!this.email) {
        this.errors.push('Email required.');
      } else if (!this.validEmail(this.email)) {
        this.errors.push('Valid email required.');
      }
      if (!this.title) {
        this.errors.push("Title required.");
      }
      if (!this.message) {
        this.errors.push("Message required.");
      }

      if (!this.errors.length) {
        try {
          emailjs.sendForm('service_8xsw9aa', 'template_19ompn2', e.target,
              'user_OReXS1Etru6y4xeTBAltW', {
                name: this.name,
                email: this.email,
                title: this.title,
                message: this.message
              })

        } catch (error) {
          console.log({error})
        }
        this.$modal.show(Modal)
        setTimeout(()=>{
          this.$modal.hideAll()
        },4000);
        this.name = ''
        this.email = ''
        this.title = ''
        this.message = ''

      }

      e.preventDefault();
    },
    validEmail: function (email) {
      const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      return re.test(email);
    },
    sendEmail(e) {
      try {
        emailjs.sendForm('service_8xsw9aa', 'template_19ompn2', e.target,
            'user_OReXS1Etru6y4xeTBAltW', {
              name: this.name,
              email: this.email,
              title: this.title,
              message: this.message
            })

      } catch (error) {
        console.log({error})
      }
      // Reset form field
      this.name = ''
      this.email = ''
      this.title = ''
      this.message = ''
    },
    coverImage: function (path){
      return require("@/" + path);
    }
  }
}
</script>

