<template>
  <div class="boat-transfers">
    <div class="boat-transfers-landing">
      <video
          src=""
          type="video/mp4" autoplay poster="../assets/boatTransfer.jpg"></video>
      <div class="boat-transfers-overlay">
        <div class="boat-transfers-inner">
          <h1>Speed boat hvar transfers</h1>
          <p>Get to / from Hvar in the most comfortable way</p>
        </div>
      </div>
      <div class="boat-transfers-details">
        <div class="boat-transfers-left-details">
          <ul>
            <li class="boat-transfers-title-no-underline"><h4>to/from Hvar</h4></li>
            <li>
              <p>split airport</p>
            </li>
            <li>
              <p>split town</p>
            </li>
            <li>
              <p>bol</p>
            </li>
            <li>
              <p>vis town</p>
            </li>
            <li>
              <p>vela luka</p>
            </li>
            <li>
              <p>korcula</p>
            </li>
            <li>
              <p>dubrovnik</p>
            </li>
          </ul>


        </div>
        <div class="boat-transfers-middle-details">
          <ul>
            <li class="boat-transfers-title-no-underline"><h4>standard</h4></li>
            <li>
              <p>450 eur</p>
            </li>
            <li>
              <p>430 eur</p>
            </li>
            <li>
              <p>430 eur</p>
            </li>
            <li>
              <p>380 eur</p>
            </li>
            <li>
              <p>450 eur</p>
            </li>
            <li>
              <p>580 eur</p>
            </li>
            <li>
              <p>1300 eur</p>
            </li>
          </ul>
        </div>
        <div class="boat-transfers-right-details">
          <ul>
            <li class="boat-transfers-title-no-underline"><h4>Deluxe</h4></li>
            <li>
              <p>900 eur</p>
            </li>
            <li>
              <p>850 eur</p>
            </li>
            <li>
              <p>850 eur</p>
            </li>
            <li>
              <p>500 eur</p>
            </li>
            <li>
              <p>850 eur</p>
            </li>
            <li>
              <p>900 eur</p>
            </li>
            <li>
              <p>2100 eur</p>
            </li>

          </ul>
        </div>
      </div>
      <div class="boat-transfers-description">
        <div class="boat-transfers-desc-right">
          <img src="../assets/rentCover.jpg" alt="hvar boat rental">
        </div>
        <div class="boat-transfers-desc-left">
          <h4>speed boat hvar transfers details</h4>
          <div class="boat-transfers-underline"></div>
          <p>One of the best ways to truly enjoy the many beaches at Hvar resorts and Hvar Island is by hopping between
            the Islands. It can be arranged quite easily when you book with us for a tour of the Hvar Island
            Croatia.</p>
          <p>You can go from the historical Archipelago to your desired destination on Hvar Island with our boats.
            Additionally, Hvar cruise and Hvar boats also allow flexibility for the tour to be started from the town of
            Hvar or any other Dalmatian Island. We address and accommodate requests for a huge variety of boat
            tours. </p>
          <p>However, let us guide you about the most famous and widely used transfer – From Split to Hvar and from
            Split airport to Hvar. Let us have access to your flight details and desired time of travel – leave the rest
            on us to manage.</p>
        </div>
      </div>
      <div class="landing-contact">
        <div class="contact-inner">
          <h3>Enquire</h3>
          <div v-if="errors.length">
            <b>Please correct the following error(s):</b>
            <ul>
              <li v-for="error in errors" :key="error.id">{{ error }}</li>
            </ul>
          </div>
          <div class="contact-line"></div>
          <form @submit="checkForm">
            <input type="text" v-model="name" name="name" class="name" placeholder="NAME">
            <input type="text" v-model="email" name="email" class="email" placeholder="E-MAIL">
            <input type="text" v-model="title" name="title" class="title" placeholder="TITLE">
            <textarea v-model="message" name="message" class="content" id="" cols="30" rows="10"
                      placeholder="ENQUIRY "></textarea>
            <button type="submit">Send</button>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import emailjs from 'emailjs-com'
import Modal from "@/views/Modal";

export default {
  metaInfo() {
    return {
      title: ' Hvar Transfers | Transfers From Split To Hvar',
      meta: [
        {name: "robots", content: "index,follow"},
        {
          name: 'description',
          content: 'The best way to get from Split to Hvar or any other destination is by Speed boat. Our speed boats with crew will give you the best possible experience on the sea. Book now!'

        }

      ],
      link: [
        {rel: 'favicon', href: 'logo.ico'}
      ]
    }
  },
  data() {
    return {
      name: '',
      email: '',
      title: '',
      message: '',
      errors: [],
    }
  },
  methods: {
    checkForm(e) {
      this.errors = [];

      if (!this.name) {
        this.errors.push("Name required.");
      }
      if (!this.email) {
        this.errors.push('Email required.');
      } else if (!this.validEmail(this.email)) {
        this.errors.push('Valid email required.');
      }
      if (!this.title) {
        this.errors.push("Title required.");
      }
      if (!this.message) {
        this.errors.push("Message required.");
      }

      if (!this.errors.length) {
        try {
          emailjs.sendForm('service_8xsw9aa', 'template_19ompn2', e.target,
              'user_OReXS1Etru6y4xeTBAltW', {
                name: this.name,
                email: this.email,
                title: this.title,
                message: this.message
              })

        } catch (error) {
          console.log({error})
        }
        this.$modal.show(Modal)
        setTimeout(() => {
          this.$modal.hideAll()
        }, 4000);
        this.name = ''
        this.email = ''
        this.title = ''
        this.message = ''

      }

      e.preventDefault();
    },
    validEmail: function (email) {
      const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      return re.test(email);
    },
    sendEmail(e) {
      try {
        emailjs.sendForm('service_8xsw9aa', 'template_19ompn2', e.target,
            'user_OReXS1Etru6y4xeTBAltW', {
              name: this.name,
              email: this.email,
              title: this.title,
              message: this.message
            })

      } catch (error) {
        console.log({error})
      }
      // Reset form field
      this.name = ''
      this.email = ''
      this.title = ''
      this.message = ''
    },
  }
}
</script>