<template>
  <div class="home">
    <div class="home-landing">
      <video
          src="../assets/southvideo.mp4"
          type="video/mp4" poster="../assets/redrocks3.jpg" autoplay loop></video>
      <div class="home-overlay">
        <div class="home-inner">
          <h1>Hvar boat tours & rentals</h1>
          <p>for your exceptional summer</p>
        </div>
      </div>
      <div class="home-small-bar">
        <p>So, you want to know more about, the best speed boat tours, Hvar boat rentals and Transfers from Hvar? You came to the right place!</p>
      </div>
      <div class="home-landing-second">
        <div class="landing-group-tours box">
          <router-link to="/blue-caves-croatia">
            <div class="images image">
              <div class="overlay-title">
                <h4 class="hover-title">group boat tours</h4>
                <div class="hover-title-line"></div>
                <p>Discover Blue cave, green cave, Stiniva bay and palmizana beach</p>
              </div>
              <h4 class="title">group boat tours</h4>
            </div>
          </router-link>
        </div>
        <div class="landing-private-tours box">
          <router-link to="/hvar-boat-tours-top-rated-adventures">
            <div class="images image">
              <div class="overlay-title">
                <h4 class="hover-title">private boat tours</h4>
                <div class="hover-title-line"></div>
                <p>Enjoy unique tailored speed boat tours and discover Blue cave, the best hvar beaches, zlatni rat
                  beach
                  and amazing pakleni islands</p>
              </div>
              <h4 class="title">private boat tours</h4>
            </div>
          </router-link>
        </div>
        <div class="landing-boat-rentals box">
          <router-link to="/hvar-boat-hire-adriatics-hidden-gems">
            <div class="images image">
              <div class="overlay-title">
                <h4 class="hover-title">boat rentals</h4>
                <div class="hover-title-line"></div>
                <p>Rent a boat with crew in Hvar and explore maze of pakleni islands, unique blue cave, or one of the
                  best
                  beaches in Europe Stiniva</p>
              </div>
              <h4 class="title">boat rentals</h4>
            </div>
          </router-link>
        </div>
        <div class="landing-transfers box">
          <router-link to="/from-split-to-Hvar-transfer">
            <div class="images image">
              <div class="overlay-title">
                <h4 class="hover-title">Boat transfer</h4>
                <div class="hover-title-line"></div>
                <p>Speed boat trasfers from/to Split airport</p>
              </div>
              <h4 class="title"> Boat transfers</h4>
            </div>
          </router-link>
        </div>
      </div>
      <div class="landing-contact">
        <div class="contact-inner">
          <h3>Enquire</h3>
          <div v-if="errors.length">
            <b>Please correct the following error(s):</b>
            <ul>
              <li v-for="error in errors" :key="error.id">{{ error }}</li>
            </ul>
          </div>
          <div class="contact-line"></div>
          <form @submit="checkForm">
            <input type="text" v-model="name" name="name" class="name" placeholder="NAME">
            <input type="text" v-model="email" name="email" class="email" placeholder="E-MAIL">
            <input type="text" v-model="title" name="title" class="title" placeholder="TITLE">
            <textarea v-model="message" name="message" class="content" id="" cols="30" rows="10"
                      placeholder="ENQUIRY "></textarea>
            <button type="submit">Send</button>
          </form>
        </div>
      </div>
    </div>

  </div>
</template>
<script>
import emailjs from 'emailjs-com'
import Modal from "@/views/Modal"
export default{
  metaInfo() {
    return {
      title: ' Hvar Boat Tours | Boat Rentals | The Best Things To Do In 2024.  ',
      meta: [
        {name: "robots", content: "index,follow"},
        {
          name: 'description',
          content: 'Hvar Boating is a top boat tour agency in Hvar. Book now! The best things to do in Hvar. Amazing Hvar boat tours and boat rentals, famous tour to Blue Cave from Hvar, Pakleni islands, Palmizana beach and visit the most beautiful Hvar beaches'
        }

      ],
      link: [
        {rel: 'shortcut icon', href: 'logo.ico'}
      ],
      script: [{
        type: 'application/ld+json',
        json: {
          "@context": "http://schema.org",
          "@type": [
            "TravelAgency",
            "TouristInformationCenter"
          ],
          "image": "../static/images/logo.png",
          "@id": "https://www.hvarboating.com",
          "name": "Hvar Boating",
          "description": "Hvar Boating is a top tour agency in Hvar, you can choose between boat rentals, amazing boat tours, famous tour to Blue Cave from Hvar, Paklinski islands, Zlatni Rat and the most beautiful Hvar beaches",
          "openingHours": [
            "Mo-Fr 09:00-23:00",
            "Sa 09:00-23:00",
            "Su 09:00-23:00"
          ],
          "geo": {
            "@type": "GeoCoordinates",
            "latitude": 43.171989,
            "longitude": 16.441758
          },

          "priceRange": "$$$",
          "currenciesAccepted": "EUR",
          "paymentAccepted": "Cash, Credit Card",
          "url": "http://www.hvarboating.com/",
          "telephone": "+385918963726",
          "sameAs": [
            "https://www.facebook.com/boatinghvar",
            "https://www.instagram.com/boatinghvar"
          ],
          "address": {
            "@type": "PostalAddress",
            "addressLocality": "Hvar",
            "addressRegion": "Splitsko-Dalmatinska",
            "postalCode": "21450",
            "streetAddress": "Jurja Matijevića 17"
          },
          "email": "boatinghvar@gmail.com"
        }
      }]
    }
  },
  data(){
    return{
      name: '',
      email: '',
      title: '',
      message: '',
      errors: [],
    }
  },

  methods: {
    checkForm(e) {
      this.errors = [];

      if (!this.name) {
        this.errors.push("Name required.");
      }
      if (!this.email) {
        this.errors.push('Email required.');
      } else if (!this.validEmail(this.email)) {
        this.errors.push('Valid email required.');
      }
      if (!this.title) {
        this.errors.push("Title required.");
      }
      if (!this.message) {
        this.errors.push("Message required.");
      }

      if (!this.errors.length) {
        try {
          emailjs.sendForm('service_8xsw9aa', 'template_19ompn2', e.target,
              'user_OReXS1Etru6y4xeTBAltW', {
                name: this.name,
                email: this.email,
                title: this.title,
                message: this.message


              })


        } catch (error) {
          console.log({error})
        }
        this.$modal.show(Modal)
        setTimeout(()=>{
          this.$modal.hideAll()
        },4000);

        this.name = ''
        this.email = ''
        this.title = ''
        this.message = ''

      }

      e.preventDefault();
    },
    validEmail: function (email) {
      const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      return re.test(email);
    },
    sendEmail(e) {
      try {
        emailjs.sendForm('service_8xsw9aa', 'template_19ompn2', e.target,
            'user_OReXS1Etru6y4xeTBAltW', {
              name: this.name,
              email: this.email,
              title: this.title,
              message: this.message
            })

      } catch (error) {
        console.log({error})
      }
      // Reset form field
      this.name = ''
      this.email = ''
      this.title = ''
      this.message = ''
    },



  }
}
</script>

