<template>
  <div class="accordion">
    <h1>frequently asked questions</h1>
    <p>These are the most common questions from our quests </p>
    <VsaList v-for="question in questions" :key="question.id">
      <!-- Here you can use v-for to loop through items  -->
      <VsaItem>
        <VsaHeading>
          {{ question.title }}
        </VsaHeading>

        <VsaContent>
          <p v-html="question.content"></p>
        </VsaContent>
      </VsaItem>
    </VsaList>
  </div>
</template>

<script>
import {
  VsaList,
  VsaItem,
  VsaHeading,
  VsaContent
} from 'vue-simple-accordion';
import 'vue-simple-accordion/dist/vue-simple-accordion.css';
import faq from '../data/faq.json'

export default {
  metaInfo() {
    return {
      title: ' Frequently Asked Questions About Hvar And Boat Tours ',
      meta: [
        {name: "robots", content: "index,follow"},
        {
          name: 'description',
          content: 'Hvar Boating is a top tour agency in Hvar. Book now! The best things to do in Hvar. Amazing Hvar boat tours & rentals, famous tour to Blue Cave from Hvar, Pakleni islands, Palmizana beach and visit the most beautiful Hvar beaches'
        }

      ],
      link: [
        {rel: 'shortcut icon', href: 'logo.ico'}
      ],
      script: [{
        type: 'application/ld+json',
        json: {"@context": "https://schema.org",
          "@type": "FAQPage",
          "mainEntity": [
            {
              "@type": "Question",
              "name": "How to visit Blue Cave in Croatia ?",
              "acceptedAnswer": {
                "@type": "Answer",
                "text": "A.\tBlue Cave can only be accessed via a boat and is situated on Island Bisevo of Hvar Island. Fortunately, you’re in luck as we offer Hvar boat and Hvar cruise tours to the blue cave."
              }
            },
            {
              "@type": "Question",
              "name": "Where is Blue Cave?",
              "acceptedAnswer": {
                "@type": "Answer",
                "text": "A.\tThe blue cave is an underground sea cave situated in the Island of Bisevo. The Island of Bisevo is about 4.5 nautical miles from komiza, the Croatian Adriatic sea. "
              }
            },
            {
              "@type": "Question",
              "name": "Can you swim in the Blue Cave ?",
              "acceptedAnswer": {
                "@type": "Answer",
                "text": "Unfortunately no. Blue Cave in Croatia is currently protected by the UNESCO as the park of nature, and park rangers do not allow to swim in the cave.Also there is alot of people there so it's not safe to swim inside."
              }
            },
            {
              "@type": "Question",
              "name": "How to get to Hvar from Split?",
              "acceptedAnswer": {
                "@type": "Answer",
                "text": "The only way to get to Hvar from Split is by the boat, provide us your flight details, and our driver will wait you at the airport an drive you to our boat<a href=/contact/> click here </a> and book your transfer"
              }
            },
            {
              "@type": "Question",
              "name": "How to go from Hvar to Paklinski islands?",
              "acceptedAnswer": {
                "@type": "Answer",
                "text": "You have numerous taxi boats which are going to take you to the island of your choice(There is actually 12 of them!). If you would like to see more than one island in a day, you can always book one of <a href=/hvar-boat-rental/>our boats</a>(hyperlink) , and explore them all."
              }
            }
          ]}
      }]
    }
  },

  data(){
    return{
    questions:faq
    }

},
  components: {
    VsaList,
    VsaItem,
    VsaHeading,
    VsaContent,
  }


}
</script>