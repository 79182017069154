<template>
  <div class="boat-tours">
    <div class="boat-tours-landing">
      <video
          src="../assets/cover.mp4"
          type="video/mp4" poster="../assets/bluecave.jpg" autoplay loop></video>
      <div class="boat-tours-overlay">
        <div class="boat-tours-inner">
          <h1>Experience the Magic of Hvar: Top-Rated Boat Tours and More</h1>
          <p>tailored made boat tours just for you</p>
        </div>
      </div>
      <div class="boat-tours-small-bar">
        <h2>Exploring Hvar: A Variety of Boat Tours</h2>
        <p>Hvar, a gem nestled in the Adriatic Sea, is not just renowned for its stunning lavender fields and ancient
          architecture; it's also a haven for sea enthusiasts and adventurers alike. The island offers a diverse array
          of boat tours, each promising a unique way to experience the island's breathtaking beauty and hidden
          treasures. Whether you're seeking a serene escape or an adrenaline-fueled adventure, Hvar has something
          special for everyone.</p>
      </div>
    </div>
    <div class="boat-tours-landing-second">
      <div class="landing-group-tours box" v-for="tour in boatTours" :key="tour.id">
        <router-link
            :to="{name: 'details', params:{id: tour.id, title: tour.seoTitle, content: tour.seoContent, markup:tour.markup}}">
          <div class="image"
               v-bind:style="{'background': 'url('+ coverImage(tour.image) + ') no-repeat center center', 'background-size': 'cover'}">
            <span role="img" aria-label="[hvar boat tours]"></span>
            <div class="overlay-title">
              <h4 class="hover-title">{{ tour.hoverTitle }}</h4>
              <div class="hover-title-line"></div>
              <p>{{ tour.shortDescription }}</p>
            </div>
            <h4 class="title">{{ tour.title }}</h4>
          </div>
        </router-link>
      </div>
    </div>
    <div class="landing-contact">
      <div class="contact-inner">
        <h3>Enquire</h3>
        <div v-if="errors.length">
          <b>Please correct the following error(s):</b>
          <ul>
            <li v-for="error in errors" :key="error.id">{{ error }}</li>
          </ul>
        </div>
        <div class="contact-line"></div>
        <form @submit="checkForm">
          <input type="text" v-model="name" name="name" class="name" placeholder="NAME">
          <input type="text" v-model="email" name="email" class="email" placeholder="E-MAIL">
          <input type="text" v-model="title" name="title" class="title" placeholder="TITLE">
          <textarea v-model="message" name="message" class="content" id="" cols="30" rows="10"
                    placeholder="ENQUIRY "></textarea>
          <button type="submit">Send</button>
        </form>
      </div>
    </div>
  </div>
</template>
<script>
import tours from '@/data/privateToursData.json'
import emailjs from 'emailjs-com'
import Modal from "@/views/Modal";

export default {
  metaInfo() {
    return {
      title: ' Explore Hvar: Top-Rated Boat Tours & Exclusive Offers  ',
      meta: [
        {name: "robots", content: "index,follow"},
        {
          name: 'description',
          content: 'Dive into the beauty of Hvar with our top-rated boat tours. Discover hidden coves, majestic cliffs, and exclusive offers tailored just for you. Book your adventure today!'
        }

      ],
      link: [
        {rel: 'favicon', href: 'logo.ico'}
      ]
    }
  },
  data() {
    return {

      boatTours: tours,
      name: '',
      email: '',
      title: '',
      message: '',
      errors: [],

    }
  },
  methods: {
    checkForm(e) {
      this.errors = [];

      if (!this.name) {
        this.errors.push("Name required.");
      }
      if (!this.email) {
        this.errors.push('Email required.');
      } else if (!this.validEmail(this.email)) {
        this.errors.push('Valid email required.');
      }
      if (!this.title) {
        this.errors.push("Title required.");
      }
      if (!this.message) {
        this.errors.push("Message required.");
      }

      if (!this.errors.length) {
        try {
          emailjs.sendForm('service_8xsw9aa', 'template_19ompn2', e.target,
              'user_OReXS1Etru6y4xeTBAltW', {
                name: this.name,
                email: this.email,
                title: this.title,
                message: this.message
              })

        } catch (error) {
          console.log({error})
        }
        this.$modal.show(Modal)
        setTimeout(() => {
          this.$modal.hideAll()
        }, 4000);
        this.name = ''
        this.email = ''
        this.title = ''
        this.message = ''

      }

      e.preventDefault();
    },
    validEmail: function (email) {
      const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      return re.test(email);
    },
    sendEmail(e) {
      try {
        emailjs.sendForm('service_8xsw9aa', 'template_19ompn2', e.target,
            'user_OReXS1Etru6y4xeTBAltW', {
              name: this.name,
              email: this.email,
              title: this.title,
              message: this.message
            })

      } catch (error) {
        console.log({error})
      }
      // Reset form field
      this.name = ''
      this.email = ''
      this.title = ''
      this.message = ''
    },
    coverImage: function (path) {
      return require("@/" + path);
    }
  },


}
</script>

