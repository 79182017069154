<template>
  <div class="weather">
    <div class="weather-landing">
      <video
          src=""
          type="video/mp4" autoplay loop poster="../assets/mlini.jpg"></video>
      <div class="weather-overlay">
        <div class="weather-inner">
          <h1>Hvar weather</h1>
          <div class="underline"></div>
          <p>For perfectly planned vacation</p>

        </div>
      </div>
      <div class="weather-description">
        <div class="weather-desc-right">
          <div class="weatherImage"></div>
        </div>
        <div class="weather-desc-left">
          <h4>hvar weather facts</h4>
          <div class="weather-underline"></div>
          <p>Hvar Island Croatia offers the best temperature during May, September, and October. The weather is
            extremely pleasant, delightful with min 20 degrees celsius and a maximum of 25 degrees celsius. People
            looking to travel in warm months should book in July and August, as those are the warmest months. However,
            from October till January, there are high chances of precipitation.</p>
          <p class="lastDiv">August is the warmest month with 30 degrees celsius temperature, while January is
            coldest with a temperature of 11 degrees celsius. Avoid booking in November as it is the month of Rain.
            People avoiding dry weather should stay away from booking in July as it is the driest month. </p>
        </div>

      </div>
      <div class="landing-contact">
        <div class="contact-inner">
          <h3>Enquire</h3>
          <div v-if="errors.length">
            <b>Please correct the following error(s):</b>
            <ul>
              <li v-for="error in errors" :key="error.id">{{ error }}</li>
            </ul>
          </div>
          <div class="contact-line"></div>
          <form @submit="checkForm">
            <input type="text" v-model="name" name="name" class="name" placeholder="NAME">
            <input type="text" v-model="email" name="email" class="email" placeholder="E-MAIL">
            <input type="text" v-model="title" name="title" class="title" placeholder="TITLE">
            <textarea v-model="message" name="message" class="content" id="" cols="30" rows="10"
                      placeholder="ENQUIRY "></textarea>
            <button type="submit">Send</button>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import emailjs from 'emailjs-com'
import Modal from "@/views/Modal";


export default {
  metaInfo() {
    return {
      title: ' Interesting Hvar weather facts  ',
      meta: [
        {name: "robots", content: "index,follow"},
        {
          name: 'description',
          content: 'These are some Hvar island weather facts to help you organize your vacation or boat tour in Hvar'
        }

      ],
      link: [
        {rel: 'favicon', href: 'logo.ico'}
      ]
    }
  },

  data() {
    return {
      name: '',
      email: '',
      title: '',
      message: '',
      errors: [],
    }
  },

  mounted() {
    const plugin = document.createElement("script");
    plugin.setAttribute(
        "src",
        "https://widgets.bokun.io/assets/javascripts/apps/build/BokunWidgetsLoader.js?bookingChannelUUID=e01dcccd-6e0d-4ece-81e9-2c85dd34e5ff"
    );
    plugin.async = true;
    document.head.appendChild(plugin);

  },

  methods: {
    checkForm(e) {
      this.errors = [];

      if (!this.name) {
        this.errors.push("Name required.");
      }
      if (!this.email) {
        this.errors.push('Email required.');
      } else if (!this.validEmail(this.email)) {
        this.errors.push('Valid email required.');
      }
      if (!this.title) {
        this.errors.push("Title required.");
      }
      if (!this.message) {
        this.errors.push("Message required.");
      }

      if (!this.errors.length) {
        try {
          emailjs.sendForm('service_8xsw9aa', 'template_19ompn2', e.target,
              'user_OReXS1Etru6y4xeTBAltW', {
                name: this.name,
                email: this.email,
                title: this.title,
                message: this.message
              })

        } catch (error) {
          console.log({error})
        }
        this.$modal.show(Modal)
        setTimeout(() => {
          this.$modal.hideAll()
        }, 4000);
        this.name = ''
        this.email = ''
        this.title = ''
        this.message = ''

      }

      e.preventDefault();
    },
    validEmail: function (email) {
      const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      return re.test(email);
    },
    sendEmail(e) {
      try {
        emailjs.sendForm('service_8xsw9aa', 'template_19ompn2', e.target,
            'user_OReXS1Etru6y4xeTBAltW', {
              name: this.name,
              email: this.email,
              title: this.title,
              message: this.message
            })

      } catch (error) {
        console.log({error})
      }
      // Reset form field
      this.name = ''
      this.email = ''
      this.title = ''
      this.message = ''
    },
  }

};
</script>