<template>
  <div class="private-tour">
    <div class="private-tour-landing" v-for="tour in privateTour" :key="tour.id">
      <div class="private-tour-landing-inner" v-if="proId === tour.id">
        <video
            src=""
            type="video/mp4" autoplay loop :poster="coverImage(tour.image)"></video>
        <div class="private-tour-overlay">
          <div class="private-tour-inner">
            <h1>{{ tour.title }}</h1>
            <p>{{ tour.subtitle }}</p>
            <div class="buttons">
              <button class=" bokunButton left-button" :data-src="tour.bokunStandardButton" data-testid="widget-book-button">Book standard option!</button>
              <button class=" bokunButton" :data-src="tour.bokunDeluxeButton" data-testid="widget-book-button">Book deluxe option!</button>
            </div>
          </div>
        </div>
        <div class="private-tour-details">
          <div class="private-tour-left-details">
            <ul>
              <li class="private-tour-title-no-underline"><h4>Tour Summary</h4></li>
              <li v-for="item in tour.tourSummary" :key="item.id">
                <p v-html="item.tourSummaryItem"></p>
              </li>

            </ul>


          </div>
          <div class="private-tour-right-details">

            <ul>
              <li class="private-tour-title-no-underline"><h4>Aditional information</h4></li>
              <li v-for="addItem in tour.additionalInformation" :key="addItem.id">
                <p v-html="addItem.addInfItem"></p>
              </li>

            </ul>

            <ul class="private-tour-highlight">
              <li class="private-tour-title-no-underline"><h4>TOUR HIGHLIGHTS </h4></li>
              <li><p v-html="tour.tourHighlights"></p></li>
            </ul>
          </div>
        </div>
        <div class="private-tour-description">
          <div class="private-tour-desc-right">
            <div class="descImage mobile-image" v-bind:style="{'background': 'url('+ coverImage(tour.descImageOne) + ') no-repeat center center', 'background-size': 'cover'}"><span role="img" aria-label="hvar tours blue cave"></span></div>
            <div class="descImage" v-bind:style="{'background': 'url('+ coverImage(tour.descImageTwo) + ') no-repeat center center', 'background-size': 'cover'}"><span role="img" aria-label="boat trips hvar"></span></div>

          </div>
          <div class="private-tour-desc-left">
            <h4>{{ tour.descriptionTitle }}</h4>
            <div class="private-tour-underline"></div>
            <p v-html="tour.description"></p>
          </div>

        </div>
        <div class="private-tour-gallery">
          <agile :initial-slide="1" :options="myOptions">
            <img v-for="photo in tour.gallery" :key="photo.id" class="slide" :src="coverImage(photo.img)" alt="hvar boat tours">
            <template slot="prevButton"><i class="fas fa-chevron-left"></i></template>
            <template slot="nextButton"><i class="fas fa-chevron-right"></i></template>
          </agile>
        </div>
        <div class="backlink-title">
          <h4>private tours are not your cup of tea?</h4>
        </div>
        <div class="backlink">
          <div class="backlink-inner box">
            <router-link to="/blue-caves-croatia">
              <div class="groupImage image">
                <div class="overlay-title">
                  <h4 class="hover-title">group boat tours</h4>
                  <div class="hover-title-line"></div>
                  <p>Discover Blue cave, green cave, Stiniva bay and palmizana beach</p>
                </div>
                <h4 class="title">group boat tours</h4>
              </div>
            </router-link>
          </div>
          <div class="backlink-inner box">
            <router-link to="/hvar-boat-rental">
              <div class="rentalImage image">
                <div class="overlay-title">
                  <h4 class="hover-title">hvar boat rental</h4>
                  <div class="hover-title-line"></div>
                  <p>Rent a boat with crew in Hvar and explore maze of pakleni islands, unique blue cave, or one of the
                    best
                    beaches in Europe Stiniva</p>
                </div>
                <h4 class="title">hvar boat rental</h4>
              </div>
            </router-link>
          </div>
        </div>
        <div class="landing-contact">
          <div class="contact-inner">
            <h3>Enquire</h3>
            <div v-if="errors.length">
              <b>Please correct the following error(s):</b>
              <ul>
                <li v-for="error in errors" :key="error.id">{{ error }}</li>
              </ul>
            </div>
            <div class="contact-line"></div>
            <form @submit="checkForm">
              <input type="text" v-model="name" name="name" class="name" placeholder="NAME">
              <input type="text" v-model="email" name="email" class="email" placeholder="E-MAIL">
              <input type="text" v-model="title" name="title" class="title" placeholder="TITLE">
              <textarea v-model="message" name="message" class="content" id="" cols="30" rows="10"
                        placeholder="ENQUIRY "></textarea>
              <button type="submit">Send</button>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import privateToursData from '@/data/privateToursData.json'
import {VueAgile} from 'vue-agile'
import emailjs from 'emailjs-com'
import Modal from "@/views/Modal";
import store from '../store'
export default {
  metaInfo() {
    return {
      title: this.seoTitle ,
      meta: [
        {name: "robots", content: "index,follow"},
        {
          name: 'description',
          content: this.seoContent

        }

      ],
      link: [
        {rel: 'favicon', href: 'logo.ico'}
      ],
      script: [{
        type: 'application/ld+json',
        json: this.markups
      }]
    }
  },
  data() {
    const SEOdata = store.getters.getBoatTour(this.$route.params.id);
    return {
      proId: this.$route.params.id,
      seoTitle: SEOdata.seoTitle,
      seoContent: SEOdata.seoContent,
      markups: SEOdata.markup,
      privateTour: privateToursData,
      props: [privateToursData],
      name: '',
      email: '',
      title: '',
      message: '',
      errors: [],


      myOptions: {
        navButtons: false,

        responsive: [
          {
            breakpoint: 350,
            settings: {
              dots: false,
              navButtons: true
            }
          },
          {
            breakpoint: 480,
            settings: {
              dots: false,
              navButtons: true
            }
          },

          {
            breakpoint: 900,
            settings: {
              navButtons: true,
              dots: true,
              infinite: false

            }
          }
        ]
      }
    }
  },
  components: {
    agile: VueAgile
  },
  mounted () {
    const plugin = document.createElement("script");
    plugin.setAttribute(
        "src",
        "https://widgets.bokun.io/assets/javascripts/apps/build/BokunWidgetsLoader.js?bookingChannelUUID=e01dcccd-6e0d-4ece-81e9-2c85dd34e5ff"
    );
    plugin.async = true;
    document.head.appendChild(plugin);

  },
  methods: {
    checkForm(e) {
      this.errors = [];

      if (!this.name) {
        this.errors.push("Name required.");
      }
      if (!this.email) {
        this.errors.push('Email required.');
      } else if (!this.validEmail(this.email)) {
        this.errors.push('Valid email required.');
      }
      if (!this.title) {
        this.errors.push("Title required.");
      }
      if (!this.message) {
        this.errors.push("Message required.");
      }

      if (!this.errors.length) {
        try {
          emailjs.sendForm('service_8xsw9aa', 'template_19ompn2', e.target,
              'user_OReXS1Etru6y4xeTBAltW', {
                name: this.name,
                email: this.email,
                title: this.title,
                message: this.message
              })

        } catch (error) {
          console.log({error})
        }
        this.$modal.show(Modal)
        setTimeout(()=>{
          this.$modal.hideAll()
        },4000);
        this.name = ''
        this.email = ''
        this.title = ''
        this.message = ''

      }

      e.preventDefault();
    },
    validEmail: function (email) {
      const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      return re.test(email);
    },
    sendEmail(e) {
      try {
        emailjs.sendForm('service_8xsw9aa', 'template_19ompn2', e.target,
            'user_OReXS1Etru6y4xeTBAltW', {
              name: this.name,
              email: this.email,
              title: this.title,
              message: this.message
            })

      } catch (error) {
        console.log({error})
      }
      // Reset form field
      this.name = ''
      this.email = ''
      this.title = ''
      this.message = ''
    },
    coverImage: function (path){
      return require("@/" + path);
    }
  },
}
</script>