import Vuex from 'vuex'
import privateToursData from "../src/data/privateToursData.json"
import boatRentalsData from "../src/data/boatRentalsData.json"

const store = new Vuex.Store({
    state:{
        privateTour: privateToursData,
        boatRental : boatRentalsData
    },
    getters: {
        getBoatTour: state => (id) => {
            return state.privateTour.find(tour => tour.id === id) || { title: 'Not found', content: '......' }
        },
        getBoatRental: state => (id) => {
            return state.boatRental.find(rental => rental.id === id) || {title: 'Not found', content: '......'}
        }

    }

})

export default store